<template>
  <v-text-field
    v-model="control.model[control.name]"
    v-bind="control"
    class="jexy-color"
  />
</template>

<script>

// Mixin
import Control from './Mixin'

export default {
  name: 'Color',
  mixins: [ Control ]
}
</script>

<style lang="scss">

.jexy-color input {
  height: 32px;
  max-height: 32px;
}

</style>
